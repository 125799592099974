import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import {FaLinkedin } from 'react-icons/fa';

const teamMembers = [
  {
    name: "Abdur Rehman",
    role: "Founder & CEO",
    description: "I am driven by the belief that data is the key to unlocking endless possibilities. My goal is to lead with vision, innovation, and a commitment to helping businesses harness the full potential of their data. With a passion for simplifying complexity, I’ve built a team that transforms raw data into meaningful insights, enabling organizations to make smarter, more informed decisions. Together, we’re not just navigating the future of data—we’re shaping it.",
    image: require('./abdur-rehman.png'),
    socialMedia: {
      linkedin: 'https://www.linkedin.com/in/abdul92rehman/',
      twitter: 'https://twitter.com/abdur_rehman',
      instagram: 'https://www.instagram.com/abdur_rehman/',
    },
  },
  {
    name: "Abdul Rehman",
    role: "Data Analyst",
    description: "Fueled by a passion for uncovering insights, I believe data is the cornerstone of transformative progress. My mission is to empower organizations to realize the full potential of their data, turning complexity into clarity. With a commitment to innovation and impact, I work to translate raw data into actionable intelligence, enabling smarter decisions and strategic growth. Together, we’re not just advancing with data—we’re driving its future, one meaningful insight at a time.",
    image: require('./abdul-rehman.png'),
    socialMedia: {
      linkedin: 'https://www.linkedin.com/in/abdul-rehman-92761122a/',
      twitter: 'https://twitter.com/abdul_rehman',
      instagram: 'https://www.instagram.com/abdul_rehman/',
    },
  },
  {
    name: "Sameer Sheikh",
    role: "Data Scientist",
    description: "Data fuels growth, and automation brings its potential to life. With predictive modeling and automation, complex data transforms into strategic insights that streamline decisions, unlock new efficiencies, and drive impact at every level.",
    image: require('./Sameer.jpeg'),
    socialMedia: {
      linkedin: 'https://www.linkedin.com/in/sameerrsheikhh/',
      twitter: 'https://twitter.com/sameer_sheikh',
      instagram: 'https://www.instagram.com/sameer_sheikh/',
    },
  },
  {
    name: "Haroon Ali",
    role: "UI & UX Designer",
    description: "Hi, I'm Haroon Ali, a passionate UI/UX Designer, specializing in creating clean, user-centric digital experiences. With a strong foundation in graphic design and web development, I focus on delivering intuitive, visually appealing interfaces that enhance user engagement and satisfaction.",
    image: require('./Haroon.jpeg'),
    socialMedia: {
      linkedin: 'https://www.linkedin.com/in/haroon-ali-04861b328/',
      twitter: 'https://twitter.com/haroon_ali',
      instagram: 'https://www.instagram.com/haroon_ali/',
    },
  }
];

export const OurTeam = () => {
  return (
    <div className="container py-5 mx-auto">
      <h1 className="text-center mb-5">
        <span>Meet the Team</span>
      </h1>
      <div className='justify-content-center row'>
        {teamMembers.map((member, index) => (
          <div key={index} className="col-md-10 mb-4" style={{margin:'auto'}}>
            <div className="card">
              <div className="row g-0">
                <div className="col-md-2">
                  <img
                    src={member.image}
                    alt={member.name}
                    className="img-fluid object-fit-cover rounded-start h-100"
                  />
                </div>
                <div className="col-md-10">
                  <div className="card-body d-flex flex-column h-100">
                    <h5 className="card-title" style={{color:'#ffa500'}}>{member.name}</h5>
                    <p className="card-subtitle mb-2 text-muted">{member.role}</p>
                    <p className="card-text small flex-grow-1">{member.description}</p>
                    <div className="mt-auto">
                      <a href={member.socialMedia.linkedin} target="_blank" rel="noopener noreferrer">
                        <FaLinkedin className="text-primary mx-1" style={{fontSize:'40px'}} />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

