import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDatabase, faChartLine, faChartPie, faRobot, faCode, faPaintBrush } from '@fortawesome/free-solid-svg-icons';

export const Services = () => {
    return (
        <div className="container py-5 justify-content-center" style={{  backgroundColor: '#f9f9f9', borderRadius: '10px' }}>
           <div className='text-center'>
           <h1 className="section-title">Our Services</h1>
            <p className="section-subtitle">Solutions That Turn Data into Action</p>
            <p style={{marginTop:'-10px', width:'80%', margin:'auto'}}>
            Transform your business with actionable insights from your data. From Data Pipelines to Advanced Analytics and Custom Dashboards, we provide solutions that empower smarter, faster decisions.
            </p>
           </div>
            <div className="row">
                {servicesData.map((service, index) => (
                    <div className="col-md-4 mb-4" key={index}>
                        <div className="service-card d-flex flex-column justify-content-between p-4" style={{ border: '1px solid #e0e0e0', borderRadius: '8px', height: '90%', transition: 'transform 0.2s' }}>
                            <div>
                                <FontAwesomeIcon icon={service.icon} size="2x" className="mb-3" style={{ color: '#FFA500' }} />
                                <h5>{service.title}</h5>
                                <p>{service.description}</p>
                            </div>
                            <a href={service.link} className="btn cus-btn w-100 mt-auto">View Our Work</a>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

const servicesData = [
    {
        title: 'Data Pipeline & ETL Solutions',
        description: 'Building and maintaining efficient data pipelines to ensure seamless integration and flow of data across various systems.',
        link: 'DataPipe',
        icon: faDatabase
    },
    {
        title: 'Custom Dashboard Solutions',
        description: 'Designing and optimizing dashboards to monitor trends and performance, enabling data-driven decision-making.',
        link: 'DsbCreationOpt',
        icon: faChartLine
    },
    {
        title: 'Advanced Analytics & Performance Insights',
        description: 'Conducting thorough data analysis to generate actionable insights that inform business strategies and operational improvements.',
        link: 'AnalyticsInsights',
        icon: faChartPie
    },
    {
        title: 'User   Behavior & Product Analytics',
        description: 'Analyzing user behavior to optimize product offerings and enhance user experience.',
        link: 'CustBehavior',
        icon: faChartLine
    },
    {
        title: 'Strategic Training & Consultation Services',
        description: 'Providing expert training and consultation to empower your team and enhance decision-making capabilities.',
        link: 'ConsulTran',
        icon: faRobot
    },
    {
        title: 'Data Integrity & Optimization Solutions',
        description: 'Ensuring the accuracy and integrity of your data to support effective business strategies.',
        link: 'ProOpt',
        icon: faChartPie
    },
    {
        title: 'AI & Machine Learning Solutions',
        description: 'Leveraging AI and machine learning to uncover insights and automate processes.',
        link: 'AiTranMacLear',
        icon: faRobot
    },
    {
        title: 'Web Development',
        description: 'Building and maintaining websites that are functional, responsive, and user-friendly.',
        link: 'Haroon',
        icon: faCode
    },
    {
        title: 'UI & UX Design',
        description: 'Crafting intuitive and visually appealing digital experiences that prioritize user needs.',
        link: 'Haroon',
        icon: faPaintBrush
    }
];
