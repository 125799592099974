import React, { useState } from 'react';
import { Navbar } from './Navbar';
import 'bootstrap/dist/css/bootstrap.min.css';
import {Container, Row, Col, Card, Button, Carousel } from 'react-bootstrap';
import DataFitt from './DataFitt.png';
import { Helmet } from 'react-helmet';



export const  Haroon=() =>{
  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex) => {
    setIndex(selectedIndex);
  };

  const projects = [
    { 
      title: <span style={{backgroundColor: 'black', borderRadius: '10px', padding: '10px'}}>Data Fitt</span>, 
      description: <span style={{backgroundColor: 'rgb(127 97 71)', borderRadius: '10px', padding: '10px'}}>Short description of Project 1</span>, 
      image: DataFitt 
    },
    { title: "Project 2", description: "Short description of Project 2", image: "https://picsum.photos/id/1015/800/400" },
    { title: "Project 3", description: "Short description of Project 3", image: "https://picsum.photos/id/1019/800/400" },
    { title: "Project 4", description: "Short description of Project 4", image: "https://picsum.photos/id/1016/800/400" },
  ];

  return (
    <div className="min-vh-100 d-flex flex-column">
      <Navbar />
      <Helmet>
        <title>Web Development & UX/UI Design - Data Fitt</title>
      </Helmet>
      <main className="flex-grow-1">
        <section className="text-center bg-Unleashing " style={{paddingTop:'120px', paddingBottom:'120px', color:'white'}}>
          <div className='container'>
            <h1 className="display-4 fw-bold mb-4">Unleashing Creativity & Functionality</h1>
            <p className="lead mb-4">
              Welcome to our creative design space, where every project narrates a story of innovation, intentionality, and user-centric design. As experts in UI/UX design and web development at Data Fitt, we're committed to crafting digital experiences that are not only visually stunning but also seamlessly intuitive.
            </p>
            <Button variant="primary" size="lg">Explore Our Work</Button>
          </div>
        </section>

        <section id="portfolio" className="py-5">
          <Container>
            <h2 className="text-center mb-5">Our Work, Our Passion</h2>
            <Carousel activeIndex={index} onSelect={handleSelect}>
              {projects.map((project, idx) => (
                <Carousel.Item key={idx}>
                  <img
                    className="d-block w-100 overflow-hidden"
                    src={project.image}
                    alt={project.title}
                  />
                  <Carousel.Caption>
                    <h3>{project.title}</h3>
                    <p>{project.description}</p>
                  </Carousel.Caption>
                </Carousel.Item>
              ))}
            </Carousel>
          </Container>
        </section>

        <section id="services" className="py-5 bg-light">
          <Container>
            <h2 className="text-center mb-5">Bringing Ideas to Life with Precision & Purpose</h2>
            <Row>
              <Col md={6} lg={3} className="mb-4">
                <Card className="h-100">
                  <Card.Body>
                    <Card.Title>UI/UX Design</Card.Title>
                    <Card.Text>From user research and persona creation to prototyping and usability testing, I ensure every step is aligned with your brand and user needs.</Card.Text>
                  </Card.Body>
                </Card>
              </Col>
              <Col md={6} lg={3} className="mb-4">
                <Card className="h-100">
                  <Card.Body>
                    <Card.Title>Responsive Web Development</Card.Title>
                    <Card.Text>Clean, high-performing code for websites and apps that scale beautifully on any device.</Card.Text>
                  </Card.Body>
                </Card>
              </Col>
              <Col md={6} lg={3} className="mb-4">
                <Card className="h-100">
                  <Card.Body>
                    <Card.Title>Interaction Design</Card.Title>
                    <Card.Text>Crafting elements that guide users effortlessly and enhance the overall engagement.</Card.Text>
                  </Card.Body>
                </Card>
              </Col>
              <Col md={6} lg={3} className="mb-4">
                <Card className="h-100">
                  <Card.Body>
                    <Card.Title>User Research & Testing</Card.Title>
                    <Card.Text>Gathering insights to validate and refine the user journey for a seamless experience.</Card.Text>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Container>
        </section>

        <section id="contact" className="py-5">
          <Container className="text-center">
            <h2 className="mb-4">Let's Create Something Extraordinary</h2>
            <p className="lead mb-4">Follow us on social media to see Our latest works, design ideas, and daily inspiration.</p>
            <div className="d-flex justify-content-center gap-3">
              <a href="https://www.instagram.com/haroon.1920/" className="text-dark">
                <i className="bi bi-instagram fs-3"></i>
                <span className="visually-hidden">Instagram</span>
              </a>
              <a href="https://www.linkedin.com/in/haroon-ali-04861b328/" className="text-dark">
                <i className="bi bi-linkedin fs-3"></i>
                <span className="visually-hidden">LinkedIn</span>
              </a>
              <a href="https://www.behance.net/haroonali-7" className="text-dark">
                <i className="bi bi-behance fs-3"></i>
                <span className="visually-hidden">Behance</span>
              </a>
            </div>
          </Container>
        </section>

        <section className="py-5 bg-light">
          <Container className="text-center">
            <h2 className="mb-4">Why Choose Us?</h2>
            <ul className="list-unstyled mb-5">
              <li>A deep understanding of user psychology</li>
              <li>A commitment to visually stunning and functional designs</li>
              <li>A process focused on client collaboration and measurable results</li>
            </ul>
            <h3 className="mb-4">Ready to Transform Your Digital Experience?</h3>
            <p className="lead mb-4">Let's discuss how we can make your vision a reality. Connect with Us today!</p>
            <Button variant="primary" size="lg">Get in Touch</Button>
          </Container>
        </section>
      </main>

      <footer className="bg-dark text-white py-4">
        <Container className="text-center">
          <p className="mb-0">&copy; {new Date().getFullYear()} <a href='https://www.linkedin.com/in/haroon-ali-04861b328/'>Haroon Ali</a>. All rights reserved.</p>
        </Container>
      </footer>
    <style jsx global>{`
        .servives {
        color: #ffa500;
        text-decoration: underline;
        }
        .Haroon {
        color: #ffa500;
        text-decoration: underline;
        }
      `}
      </style>
    </div>
  );
}