import React from 'react';
import { Navbar } from './Navbar';
import {Contact}from './Contact';
import {Footer}from './Footer';
import { Helmet } from 'react-helmet';


import './App.css';  // Import custom CSS for styling';

export const  GetinTouch=() =>{
  return (
    <div className='app'>
    <Helmet>
      <title>Get in Touch - Data Fitt</title>
    </Helmet>
      <Navbar />
      <div className="Sub-hero-section d-flex align-items-end justify-content-center" style={{position:'relative',}}>
            <video className="background-video" autoPlay loop muted style={{width:'100%'}}>
                <source src={require('../src/bgvid2.mp4')} type="video/mp4" />
            </video>
        <div className="container">
            <h1>Get In Touch</h1>
        </div>
    </div>
    <style jsx global>{`
        .contacts {
        color: #ffa500;
        text-decoration: underline;
        }
      `}
      </style>
    <Contact />
    <Footer />
    </div>
    
  );
};
