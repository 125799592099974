import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Navbar } from './Navbar';
import {Contact}from './Contact';
import {Footer}from './Footer';
import Image from '../src/consultran.png';
import { Helmet } from 'react-helmet';


export const  ConsulTran=() =>{
  return (
    <div className='app'>
    <Helmet>
      <title>Strategic Training & Consultation Services - Data Fitt</title>
    </Helmet>
      <Navbar />
      <div className="Sub-hero-section d-flex align-items-end justify-content-center" style={{position:'relative',}}>
            <video className="background-video" autoPlay loop muted style={{width:'100%'}}>
                <source src={require('../src/bgvid2.mp4')} type="video/mp4" />
            </video>
        <div className="container">
            <h1>Strategic Training & Consultation Services</h1>
        </div>
    </div>
    <div className="container my-5">
      <h2 className="text-warning mb-3">Strategic Training & Consultation Services</h2>
      <div className="mb-4 container-fluid d-flex justify-content-center" style={{backgroundColor:'#f8f9fa',padding:'20px',borderRadius:'20px'}}>
      <img src={ Image } width="60%" alt="Data Pipeline" className="img-fluid rounded-start"/>
      </div>
      <p className="mb-4">
      Each phase in this process is essential to ensuring that the dashboard serves its purpose of providing clear, actionable insights to drive business decisions.
      </p>
      <div className="row">
        <div className="col-md-12">
          <ol className="list-group">
            <li className="list-group-item border-0 ps-0">
              <h5>Project 1: Training Sessions</h5>
              <p>
                Led comprehensive training sessions to enhance the data literacy and analytical skills of teams, enabling them to independently derive actionable insights from complex datasets.
              </p>
            </li>
            <li className="list-group-item border-0 ps-0">
              <h5>Project 2: Monitoring Mechanism for Federal Ministry of Education Islamabad</h5>
              <p>
              Focuses on creating intuitive, user-friendly dashboards that effectively visualize data and key performance metrics.
              </p>
            </li>
      <li className="list-group-item border-0 ps-0">
              <h5>Project 3: Dashboard Design Consultation</h5>
              <p>
              Ongoing tracking of key metrics and system performance to ensure that the dashboard reflects up-to-date, actionable information.
              </p>
            </li>
            <li className="list-group-item border-0 ps-0">
              <h5>Project 4: Customized Data Analysis Workshops</h5>
              <p>
              This stage involves analyzing the data to uncover trends and insights that can inform decision-making and strategic actions.
              </p>
            </li>
          </ol>
        </div>
      </div>
    </div>
    <style jsx global>{`
        .servives {
        color: #ffa500;
        text-decoration: underline;
        }
        .ProOpt {
        color: #ffa500;
        text-decoration: underline;
        }
        }
      `}
      </style>
    <Contact />
    <Footer />
    </div>
  );
};
