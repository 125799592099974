import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Navbar } from './Navbar';
import {Contact}from './Contact';
import {Footer}from './Footer';
import Image from '../src/Analytics-Insights.png';
import { Helmet } from 'react-helmet';


export const  AnalyticsInsights=() =>{
  return (
    <div className='app'>
    <Helmet>
      <title>Advanced Analytics & Performance Insights - Data Fitt</title>
    </Helmet>
      <Navbar />
      <div className="Sub-hero-section d-flex align-items-end justify-content-center" style={{position:'relative',}}>
            <video className="background-video" autoPlay loop muted style={{width:'100%'}}>
                <source src={require('../src/bgvid2.mp4')} type="video/mp4" />
            </video>
        <div className="container">
            <h1>Advanced Analytics & Performance Insights</h1>
        </div>
    </div>
    <div className="container my-5">
      <h2 className="text-warning mb-3">Advanced Analytics & Performance Insights</h2>
      <p className="mb-4">
        We Have Successfully Developed And Implemented Robust Data Migration And ETL Solutions That Facilitate
        Efficient Data Transfer And Analysis. Our Portfolio Includes:
      </p>
      <div className="row">
        <div className="col-md-6">
          <ol className="list-group">
            <li className="list-group-item border-0 ps-0">
              <h5>Project 1: Statistical Data Analysis</h5>
              <p>
              Conducted comprehensive statistical analyses to uncover trends, correlations, and insights that drive strategic decisions and product improvements.
              </p>
            </li>
            <li className="list-group-item border-0 ps-0">
              <h5>Project 2: Measuring Content Performance</h5>
              <p>
              Analyzed the performance of educational content by measuring engagement, completion rates, and user satisfaction to optimize content delivery strategies.
              </p>
            </li>
          </ol>
        </div>
        <div className="col-md-6">
            <img src={ Image } width="100%" alt="Data Pipeline" className="img-fluid rounded-start"/>
        </div>
      </div>
      <ul className="list-group">
      <li className="list-group-item border-0 ps-0">
              <h5>Project 3: Statistical Modelling</h5>
              <p>
              Developed predictive models using advanced statistical techniques to forecast user behavior, content performance, and future trends, enabling data-driven decision-making.
              </p>
            </li>
            <li className="list-group-item border-0 ps-0">
              <h5>Project 4: ASER Baseline/Midline/Endline Analysis</h5>
              <p>
              Performed baseline, midline, and endline analyses for the ASER project, evaluating educational outcomes and measuring the impact of interventions over time.
              </p>
            </li>
            <li className="list-group-item border-0 ps-0">
              <h5>Project 5: Predictive Analytics for User Retention</h5>
              <p>
              Built predictive models to forecast user retention, enabling the team to identify at-risk users and implement strategies to boost long-term engagement.
              </p>
            </li>
            <li className="list-group-item border-0 ps-0">
              <h5>Project 6: Experimentation & A/B Testing</h5>
              <p>
              Designed and conducted A/B tests to measure the effectiveness of new features, content, and engagement strategies, ensuring data-backed improvements in product performance.
              </p>
            </li>
      </ul>
      <p className="mt-4">
      At Datafitt Company, key projects included statistical data analysis,
       content performance optimization, predictive modeling for user behavior and retention,
       and ASER baseline, midline, and endline analyses. Additionally,
        A/B tests were conducted to improve product performance through data-driven insights.
      </p>
    </div>
    <style jsx global>{`
        .servives {
        color: #ffa500;
        text-decoration: underline;
        }
        .AnalyticsInsights {
        color: #ffa500;
        text-decoration: underline;
        }
      `}
      </style>
    <Contact />
    <Footer />
    </div>
  );
};
