// Loading.js
import React from 'react';
import image from '../src/logo512.png';

const Loading = () => {
    return (
        <div className="main-loading-div">
            <div className="text-center inner-div">
                <img src={image} alt="DataFitt" className="loading-logo" />
                <h3 className="loading-text">Transforming Data Unlocking Possibilities...</h3>
                <div className="progress">
                    <div className="main-loading-bar"></div>
                </div>
            </div>
        <style jsx global>{`
            .loading-logo {
                width: 40%;
                padding: 40px;
            }
            .main-loading-div {
                width: 100%;
                height: 100vh;
                background-color: #333;
                display: flex;
                justify-content: center;
                align-items: center; 
                color: #fff;
                padding: 20px;
                box-sizing: border-box;
            }
            .progress {
                margin-top: 40px;
                height: 10px;
                width: 80%;
                max-width: 400px;
                position: relative;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
            }
            .main-loading-bar {
                width: 5%; 
                height: 100%;
                background-color: #ffa500;
                animation: loading 3s infinite;
            }
            @keyframes loading {
                0% {
                    width: 5%;
                }
                100% {
                    width: 95%;
                }
            }
            @media (max-width: 600px) {
                .loading-logo {
                    width: 80%;
                    padding: 10px;
                }
                .progress {
                    width: 90%;
                }
            }
        `}</style>
        </div>
    )
};

export default Loading;

