import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import HomePage from './HomePage';
import { ServicesPage } from './ServicesPage';
import { GetinTouch } from './GetinTouch';
import { AboutCompany } from './AboutCompany';
import { DataPipe } from './DataPipe';
import { DsbCreationOpt } from './DsbCreationOpt';
import { AnalyticsInsights } from './AnalyticsInsights';
import { CustBehavior } from './CustBehavior';
import { ConsulTran } from './ConsulTran';
import { ProOpt } from './ProOpt';
import { AiTranMacLear } from './AiTranMacLear';
import { Haroon } from './Haroon';
import Loading from './Loading'; // Import the Loading component

function App() {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Simulate loading time
    const timer = setTimeout(() => {
      setLoading(false);
    }, 1000); // Adjust the duration as needed

    return () => clearTimeout(timer); // Cleanup on unmount
  }, []);

  if (loading) {
    return <Loading />; // Show loading screen while loading
  }

  return (
    <Router>
      <div className="App">
        <Routes>
          <Route path="/HomePage" element={<HomePage />} />
          <Route path="/ServicesPage" element={<ServicesPage />} />
          <Route path="/GetinTouch" element={<GetinTouch />} />
          <Route path="/AboutCompany" element={<AboutCompany />} />
          <Route path="/DataPipe" element={<DataPipe />} />
          <Route path="/DsbCreationOpt" element={<DsbCreationOpt />} />
          <Route path="/AnalyticsInsights" element={<AnalyticsInsights />} />
          <Route path="/CustBehavior" element={<CustBehavior />} />
          <Route path="/ConsulTran" element={<ConsulTran />} />
          <Route path="/ProOpt" element={<ProOpt />} />
          <Route path="/AiTranMacLear" element={<AiTranMacLear />} />
          <Route path="/Haroon" element={<Haroon />} />
          <Route path="*" element={<Navigate to="/HomePage" />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;