import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import image from '../src/about-img.jpg';
import emailjs from 'emailjs-com'; // Import EmailJS

export const Contact = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Use EmailJS to send the email
    emailjs.send('service_bw2zb89', 'template_qt6coes', formData, 'lbpFzYaVL3SSakJ7M')
      .then((response) => {
        console.log('SUCCESS!', response.status, response.text);
        alert('Message sent successfully!');
        setFormData({ name: '', email: '', message: '' });
      })
      .catch((err) => {
        console.error('FAILED...', err);
        alert('Failed to send message. Please try again.');
      });
  };

  return (
    <Container className="py-5">
      <h1 className="text-warning mb-4">Contact us</h1>
      <p className="text-muted mb-5">
        Note: Need Assistance Or Want To Book A Service? Fill Out The Form, And We'll Get Back To You Soon! Or You Can Call
        Us For Instant Support.
      </p>
      
      <Row className="mb-3">
        <Col md={6} className="mb-3 mb-md-0 text-center">
          <a href='https://wa.me/923166045099'>
            <div className="bg-light p-3 rounded">
              <i className="bi bi-telephone-fill text-warning me-2"></i>
              +92 316 6045099
            </div>
          </a>
        </Col>
        <Col md={6} className="mb-3 mb-md-0 text-center">
          <a href='mailto:info@datafitt.com'>
            <div className="bg-light p-3 rounded" style={{alignItems:'center'}}>
              <i className="bi bi-envelope-fill text-warning me-2"></i>
              info@datafitt.com
            </div>
          </a>
        </Col>
      </Row>
      
      <Row style={{backgroundColor:'#f8f9fa',padding:'40px',borderRadius:'20px'}}>
        <Col md={6} className="mb-4 mb-md-0">
          <div className="ratio ratio-4x3">
            <img src={image} className="w-100 h-100 object-fit-cover" alt="Contact Us"/>
          </div>
        </Col>
        
        <Col md={6} style={{alignContent:'center'}}>
          <h2 className="mb-4">We’re Excited to Connect!</h2>
          <Form onSubmit={handleSubmit}>
            <Form.Group className="mb-3" controlId="formName">
              <Form.Control 
                type="text" 
                name="name"
                placeholder="Name" 
                value={formData.name} 
                onChange={handleChange} 
                required 
              />
            </Form.Group>
            
            <Form.Group className="mb-3" controlId="formEmail">
              <Form.Control 
                type="email" 
                name="email"
                placeholder="Email" 
                value={formData.email} 
                onChange={handleChange} 
                required 
              />
            </Form.Group>
            
            <Form.Group className="mb-3" controlId="formMessage">
              <Form.Control 
                as="textarea" 
                rows={5} 
                name="message"
                placeholder="Message" 
                value={formData.message} 
                onChange={handleChange} 
                required 
              />
            </Form.Group>
            
            <Button variant="warning" type="submit" className="px-4 py-2 w-100">
              Send Message
            </Button>
          </Form>
        </Col>
      </Row>
    </Container>
  );
};

export default Contact;