import React from 'react';
import './App.css'; // Ensure this file exists and contains necessary styles
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js'; // Use minified version for production

export const Navbar = () => {
  return (
    <nav className="navbar navbar-expand-lg navbar-light custom-sticky-navbar sticky"> 
      <a className="navbar-brand" href="HomePage">
        <img
          alt="Logo"
          height="45"
          src="/logo512.png" // Ensure this path is correct
        />
      </a>
      <button
        className="navbar-toggler"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarNav"
        aria-controls="navbarNav"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span className="navbar-toggler-icon"></span>
      </button>
      <div className="collapse navbar-collapse justify-content-end" id="navbarNav">
        <ul className="navbar-nav" style={{ margin: '0 auto' }}>
          <li className="nav-item">
            <a className="nav-link home" aria-current="page" href="HomePage">
              Home
            </a>
          </li>
          <li className="nav-item dropdown">
            <a
              className="nav-link dropdown-toggle about"
              href="#"
              id="aboutsDropdown"
              role="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              About us
            </a>
            <ul className="dropdown-menu" aria-labelledby="aboutsDropdown">
              <li>
                <a className="dropdown-item" href="AboutCompany">
                  About Us
                </a>
              </li>
              <li>
                <a className="dropdown-item disabled" href="#">
                  Blogs
                </a>
              </li>
            </ul>
          </li>
          <li className="nav-item dropdown">
            <a
              className="nav-link dropdown-toggle servives"
              href="#"
              id="servicesDropdown"
              role="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              Services
            </a>
            <ul className="dropdown-menu disabled" aria-labelledby="servicesDropdown">
              <li>
                <a className="dropdown-item services-page" href="ServicesPage">
                  All Services
                </a>
              </li>
              <li>
                <a className="dropdown-item DataPipe" href="DataPipe">
                Data Pipeline & ETL Solutions
                </a>
              </li>
              <li>
                <a className="dropdown-item DsbCreationOpt" href="DsbCreationOpt">
                Dashboard Creation and Optimization
                </a>
              </li>
              <li>
                <a className="dropdown-item AnalyticsInsights" href="AnalyticsInsights">
                Advanced Analytics & Performance Insights
                </a>
              </li>
              <li>
                <a className="dropdown-item CustBehavior" href="CustBehavior">
                User Behavior & Product Analytics
                </a>
              </li>
              <li>
                <a className="dropdown-item" href="ConsulTran">
                Strategic Training & Consultation Services
                </a>
              </li>
              <li>
                <a className="dropdown-item ProOpt" href="ProOpt">
                Data Integrity & Optimization Solutions
                </a>
              </li>
              <li>
                <a className="dropdown-item disabled" href="#" tabIndex="-1" aria-disabled="true">
                AI & Machine Learning Solutions
                </a>
              </li>
              <li>
                <a className="dropdown-item Haroon" href="Haroon">
                  Web Development
                </a>
              </li>
              <li>
                <a className="dropdown-item Haroon" href="Haroon">
                  UI & UX Design
                </a>
              </li>
            </ul>
          </li>
          <li className="nav-item">
            <a className="nav-link contacts" href="GetinTouch">
              Contact us
            </a>
          </li>
        </ul>
        <button className="btn custom-cta-btn" type="button" style={{margin: '0 5% 0 0'}}>
          <a href="GetinTouch">Get in Touch</a>
        </button>
      </div>
    </nav>
  );
};
