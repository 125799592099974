import React from 'react';
import { Image } from 'react-bootstrap';
import { Navbar } from './Navbar';
import { Contact } from './Contact';
import { Footer } from './Footer';
import { OurTeam } from './OurTeam';
import './App.css'; // Import custom CSS for styling
import { Container, Carousel } from 'react-bootstrap';
import { Helmet } from 'react-helmet';

export const AboutCompany = () => {
  const partners = [
    {
      name: "Taleemabad",
      logo: require('./tbd.jpg'),
    },
    {
      name: "xad technologies",
      logo: require('./xad.jpg'),
    },
    {
      name: "translation empire",
      logo: require('./tranemp.jpg'),
    },
    {
      name: "Up Work.com",
      logo: require('./upwork.png'),
    },
    {
      name: "Fiverr.com",
      logo: require('./fiverr.jpg'),
    },
    {
      name: "royal national college kharian",
      logo: require('./rnc.png'),
    },
    {
      name: "royal national college kharian",
      logo: require('./uom.png'),
    },
    {
      name: "royal national college kharian",
      logo: require('./bgoc.jpeg'),
    },
  ];

  const chunkArray = (array, chunkSize) => {
    const result = [];
    for (let i = 0; i < array.length; i += chunkSize) {
      result.push(array.slice(i, i + chunkSize));
    }
    return result;
  };

  const partnerChunks = chunkArray(partners, 4);

  return (
    <div className='app'>
      <Helmet>
        <title>About us - Data Fitt</title>
      </Helmet>
      <Navbar />
      <div className="Sub-hero-section d-flex align-items-end justify-content-center" style={{ position: 'relative' }}>
        <video className="background-video" autoPlay loop muted style={{ width: '100%' }}>
          <source src={require('../src/bgvid2.mp4')} type="video/mp4" />
        </video>
        <div className='col-md-12 text-center'>
          <h1>About us</h1>
        </div>
      </div>
      <div className="container my-5 ">
        <h1 className="mb-3 text-center">
          <span>Our Mission</span>
        </h1>
        <div className="bg-light p-4 rounded text-left">
          <p className="mb-0 text-left">
            At DataFitt, our mission is to empower businesses by transforming complex data into actionable insights that drive growth and innovation. We are committed to helping organizations unlock the full potential of their data through tailored solutions, fostering smarter decision-making and sustainable success. By simplifying the power of data, we enable our clients to stay ahead in a rapidly evolving world.
          </p>
        </div>
      </div>

      {/* Partners Section */}
      <Container className="text-center my-5">
        <h1 className="mb-4" style={{ color: '#ffa500' }}>
          Trusted by Organizations
        </h1>
        <Carousel
          interval={3000}
          indicators={true}
          controls={true}
          className="mb-4"
          style={{ width: '100%'}}
        >
          {partnerChunks.map((chunk, index) => (
            <Carousel.Item key={index}>
              <div className="d-flex justify-content-center">
                {chunk.map((partner, idx) => (
                  <div className="mx-4" key={idx}>
                    <Image
                      src={partner.logo}
                      alt={partner.name}
                      width={150}
                      height={150}
                      className="d-block border rounded"
                      style={{ objectFit: 'contain' }}
                    />
                  </div>
                ))}
              </div>
            </Carousel.Item>
          ))}
        </Carousel>

        <style jsx global>{`
          .about {
            color: #ffa500;
            text-decoration: underline;
          }
          .carousel-indicators [data-bs-target] {
            background-color: #0d6efd;
          }
          .carousel-indicators .active {
            background-color: #0d6efd;
          }
          .carousel-control-prev-icon,
          .carousel-control-next-icon {
            filter: invert(0.5);
          }
        `}</style>
      </Container>
      <OurTeam />
      <Contact />
      <Footer />
    </div>
  );
};

