import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Navbar } from './Navbar';
import {Contact}from './Contact';
import {Footer}from './Footer';
import Image from '../src/DsbCreationOpt.png';

export const  AiTranMacLear=() =>{
  return (
    <div className='app'>
      <Navbar />
    <div className="Sub-hero-section"  style={{position:'relative'}}>
            <video className="background-video" autoPlay loop muted style={{width:'100%'}}>
                <source src={require('../src/bgvid2.mp4')} type="video/mp4" />
            </video>
        <div className="container">
            <h1>AI Training And Machine Learning</h1>
        </div>
        <div className="container col-md-12">
            <div className="container row">
                <div className="col-md-12" style={{display:'flex',justifyContent:'center',alignItems:'center'}}>
                    <button className="cus-button filled-btn"><a href='AboutCompany'>Learn More</a></button>
                    <button className="cus-button outline-btn"><a href='GetinTouch'>Get In Touch</a></button>
                </div>
            </div>
        </div>
    </div>
    <div className="container my-5">
      <h2 className="text-warning mb-3">Dashboard Creation and Optimization</h2>
      <div className="mb-4">
      <img src={ Image } width="100%" alt="Data Pipeline" className="img-fluid rounded-start"/>
      </div>
      <p className="mb-4">
      Each phase in this process is essential to ensuring that the dashboard serves its purpose of providing clear, actionable insights to drive business decisions.
      </p>
      <div className="row">
        <div className="col-md-12">
          <ol className="list-group">
            <li className="list-group-item border-0 ps-0">
              <h5>1.Data Integration</h5>
              <p>
              This step involves aggregating and consolidating data from various sources into a unified system to ensure seamless access and accurate reporting.
              </p>
            </li>
            <li className="list-group-item border-0 ps-0">
              <h5>2.Dashboard Design</h5>
              <p>
              Focuses on creating intuitive, user-friendly dashboards that effectively visualize data and key performance metrics.
              </p>
            </li>
          </ol>
        </div>
      </div>
      <ul className="list-group">
      <li className="list-group-item border-0 ps-0">
              <h5>3.Performance Monitoring</h5>
              <p>
              Ongoing tracking of key metrics and system performance to ensure that the dashboard reflects up-to-date, actionable information.
              </p>
            </li>
            <li className="list-group-item border-0 ps-0">
              <h5>4.Actionable Insights</h5>
              <p>
              This stage involves analyzing the data to uncover trends and insights that can inform decision-making and strategic actions.
              </p>
            </li>
            <li className="list-group-item border-0 ps-0">
              <h5>5.Continuous Improvement</h5>
              <p>
              The final phase is a feedback loop aimed at refining the dashboard and processes based on new data, changing needs, and performance results.
              </p>
            </li>
      </ul>
      <p className="mt-4">
        Through These Projects At DataFitt Company, We Have Demonstrated Our Ability To Build Efficient Data Pipelines, Ensuring Seamless
        Data Flow And Powerful Analytics Capabilities. Our Expertise Lies In Transforming Complex Data Into Actionable Insights, Enhancing
        Data-Driven Decision-Making Across Organizations.
      </p>
    </div>
    <Contact />
    <Footer />
    </div>
  );
};
