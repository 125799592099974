import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Navbar } from './Navbar';
import {Contact}from './Contact';
import {Footer}from './Footer';
import Image from '../src/DsbCreationOpt.png';
import { Helmet } from 'react-helmet';


export const  DsbCreationOpt=() =>{
  return (
    <div className='app'>
    <Helmet>
      <title>Dashboard Creation and Optimization - Data Fitt</title>
    </Helmet>
      <Navbar />
      <div className="Sub-hero-section d-flex align-items-end justify-content-center" style={{position:'relative',}}>
            <video className="background-video" autoPlay loop muted style={{width:'100%'}}>
                <source src={require('../src/bgvid2.mp4')} type="video/mp4" />
            </video>
        <div className="container">
            <h1>Dashboard Creation and Optimization</h1>
        </div>
    </div>
    <div className="container my-5">
      <h2 className="text-warning mb-3">Dashboard Creation and Optimization</h2>
      <div className="mb-4">
      <img src={ Image } width="100%" alt="Data Pipeline" className="img-fluid rounded-start"/>
      </div>
      <p className="mb-4">
      Each phase in this process is essential to ensuring that the dashboard serves its purpose of providing clear, actionable insights to drive business decisions.
      </p>
      <img src='/main.jpg' className='img-fluid'/>
      <div className="row">
        <div className="col-md-12">
          <ol className="list-group">
            <li className="list-group-item border-0 ps-0">
              <h5>Project 1: ASER and TEACH Dashboard</h5>
              <p>
              Developed a comprehensive dashboard to track and analyze educational outcomes and teacher effectiveness based on the ASER and TEACH assessments.
              </p>
            </li>
            <li className="list-group-item border-0 ps-0">
              <h5>Project 2: Engagement and Stickiness Dashboard</h5>
              <p>
              Created a dashboard that monitors user engagement and stickiness, providing insights into user retention and interaction with the platform.
              </p>
            </li>
            <li className="list-group-item border-0 ps-0">
              <h5>Project 3: Exam/Test Maker Dashboard</h5>
              <p>
              Built a dashboard that allows users to create, manage, and track exams and test results, streamlining the evaluation process for educators.
              </p>
            </li>
            <li className="list-group-item border-0 ps-0">
              <h5>Project 4: LP Ratings Overview Dashboard</h5>
              <p>
              Designed a dashboard to monitor lesson plan (LP) ratings, offering an overview of the most effective content based on user feedback.
              </p>
            </li>
            <li className="list-group-item border-0 ps-0">
              <h5>Project 5: Muslim Hands Schools Activity Dashboard</h5>
              <p>
              Implemented a dashboard to track the performance and activities of Muslim Hands Schools, providing real-time insights into school operations.
              </p>
            </li>
            <li className="list-group-item border-0 ps-0">
              <h5>Project 6: Federal Ministry Dashboard</h5>
              <p>
              Developed a dashboard for the Federal Ministry to monitor key performance indicators related to school and teacher performance across Islamabad.
              </p>
            </li>
            <li className="list-group-item border-0 ps-0">
              <h5>Project 7: Planner Dashboard</h5>
              <p>
              Created a planner dashboard for teachers and administrators to schedule and track lesson plans, ensuring better adherence to curriculum schedules.
              </p>
            </li>
            <li className="list-group-item border-0 ps-0">
              <h5>Project 8: School Activity Dashboard</h5>
              <p>
              Built a dashboard to track various school activities, enabling administrators to monitor attendance, participation, and performance in real-time.
              </p>
            </li>
            <li className="list-group-item border-0 ps-0">
              <h5>Project 9: Teacher Training Details Dashboard</h5>
              <p>
              Designed a dashboard that tracks the progress and completion rates of teacher training programs, ensuring that training goals are met.
              </p>
            </li>
            <li className="list-group-item border-0 ps-0">
              <h5>Project 10: Video Stats on APP & LMS Dashboard</h5>
              <p>
              Developed a dashboard that tracks video usage statistics on the app and LMS, providing insights into how users engage with educational content.
              </p>
            </li>
          </ol>
        </div>
      </div>
      <p className="mt-4">
        Through These Projects At DataFitt Company, We Have Demonstrated Our Ability To Build Efficient Data Pipelines, Ensuring Seamless
        Data Flow And Powerful Analytics Capabilities. Our Expertise Lies In Transforming Complex Data Into Actionable Insights, Enhancing
        Data-Driven Decision-Making Across Organizations.
      </p>
    </div>
    
    <style jsx global>{`
        .servives {
        color: #ffa500;
        text-decoration: underline;
        }
        .DsbCreationOpt {
        color: #ffa500;
        text-decoration: underline;
        }
      `}
      </style>
    <Contact />
    <Footer />
    </div>
  );
};
