import React, { useState } from 'react';
import emailjs from 'emailjs-com';

export const Footer = () => {
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');

    const handleSubmit = (e) => {
        e.preventDefault();

        // Replace 'YOUR_SERVICE_ID', 'YOUR_TEMPLATE_ID', 'YOUR_USER_ID' with your actual EmailJS IDs
        emailjs.send('service_bw2zb89', 'template_fp6rmb5', { user_email: email }, 'lbpFzYaVL3SSakJ7M')
            .then((response) => {
                setMessage('Thank you for subscribing!');
                setEmail('');
            })
            .catch((error) => {
                console.error('Failed to send email:', error);
                setMessage('Failed to subscribe. Please try again.');
            });
    };

    return (
        <footer className="text-dark py-4" style={{ backgroundColor: 'rgb(242 242 242)' }}>
            <div className="container">
                <div className="row">
                    {/* Company Info */}
                    <div className="col-md-4 mb-4 mb-md-0">
                        <div className="d-flex align-items-center mb-3">
                            <div className="me-3">
                                <img alt="Logo" src="/logo512b.png" height="80" className="rounded" />
                            </div>
                        </div>
                        <p className="text-muted">
                            We use data to drive growth, improve products, and enhance user engagement.
                        </p>
                    </div>
                    <div className='col-md-2'></div>
                    {/* Newsletter Subscription Form */}
                    <div className="col-md-6">
                        <div className="container bg-light border rounded p-4">
                            <h3 className="h5">Stay Updated</h3>
                            <p className="text-muted">
                                Get notified about new insights, products, and services. No spam, ever. Unsubscribe anytime.
                            </p>
                            {message && <p className="text-success">{message}</p>}
                            <form className="input-group" onSubmit={handleSubmit}>
                                <input
                                    type="email"
                                    placeholder="Enter your email"
                                    className="form-control"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    required
                                />
                                <button type="submit" className="btn btn-primary" style={{ backgroundColor: '#ffa500', borderColor: '#f8f9fa' }}>
                                    Subscribe
                                </button>
                            </form>
                        </div>
                    </div>
                </div>

                {/* Bottom Bar */}
                <div className="mt-4 pt-4 border-top d-md-flex justify-content-between align-items-center text-muted text-center">
                    <p>&copy; 2024, Designed & Developed By <a href="https://www.linkedin.com/in/haroon-ali-04861b328/" target="_blank" rel="noopener noreferrer" style={{textDecoration: 'underline',}}>Haroon Ali</a></p>
                </div>
            </div>
        </footer>
    );
};