import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Navbar } from './Navbar';
import {Contact}from './Contact';
import {Footer}from './Footer';
import Image from '../src/userbehavior-productanlytics.png';
import { Helmet } from 'react-helmet';


export const  CustBehavior=() =>{
  return (
    <div className='app'>
    <Helmet>
      <title>User Behavior & Product Analytics - Data Fitt</title>
    </Helmet>
      <Navbar />
      <div className="Sub-hero-section d-flex align-items-end justify-content-center" style={{position:'relative',}}>
            <video className="background-video" autoPlay loop muted style={{width:'100%'}}>
                <source src={require('../src/bgvid2.mp4')} type="video/mp4" />
            </video>
        <div className="container">
            <h1>User Behavior & Product Analytics</h1>
        </div>
    </div>
    <div className="container my-5">
      <h2 className="text-warning mb-3">User Behavior & Product Analytics</h2>
      <div className="mb-4 container-fluid d-flex justify-content-center" style={{backgroundColor:'#f8f9fa',padding:'20px',borderRadius:'20px'}}>
      <img src={ Image } width="60%" alt="Data Pipeline" className="img-fluid rounded-start"/>
      </div>
      <p className="mb-4">
      Each phase in this process is essential to ensuring that the dashboard serves its purpose of providing clear, actionable insights to drive business decisions.
      </p>
      <div className="row">
        <div className="col-md-12">
          <ol className="list-group">
            <li className="list-group-item border-0 ps-0">
              <h5>Project 1: Identifying User Activation Triggers for Long-Term Retention</h5>
              <p>
              Analyzed key moments in the user journey to identify activation triggers that drive long-term engagement and retention.
              </p>
            </li>
            <li className="list-group-item border-0 ps-0">
              <h5>Project 2: Deep Dive into User Engagement – Lesson Plan Start Days and Long-Term Retention</h5>
              <p>
              Conducted a detailed study on how lesson plan start days impact user retention, leading to more effective onboarding strategies.
              </p>
            </li>
          </ol>
        </div>
      </div>
      <ul className="list-group">
      <li className="list-group-item border-0 ps-0">
              <h5>Project 3: User Behavior Metrics (DAU, WAU, MAU)</h5>
              <p>
              Performed continuous tracking and analysis of user activity, including Daily Active Users (DAU), Weekly Active Users (WAU), and Monthly Active Users (MAU), to assess overall platform engagement and growth.
              </p>
            </li>
            <li className="list-group-item border-0 ps-0">
              <h5>Project 4: Feature Performance Analysis</h5>
              <p>
              Analyzed user interactions with newly developed platform features, providing insights into which features increased engagement and satisfaction, leading to strategic product optimizations.
              </p>
            </li>
            <li className="list-group-item border-0 ps-0">
              <h5>Project 5: Product Usage Patterns & Feedback Loop</h5>
              <p>
              Studied user feedback and product usage patterns to identify areas for improvement, enabling continuous optimization of the platform to enhance user satisfaction and retention.
              </p>
            </li>
      </ul>
    </div>
    <style jsx global>{`
        .servives {
        color: #ffa500;
        text-decoration: underline;
        }
        .CustBehavior {
        color: #ffa500;
        text-decoration: underline;
        }
      `}
      </style>
    <Contact />
    <Footer />
    </div>
  );
};
