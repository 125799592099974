import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import { Navbar } from './Navbar';
import { HeroSection } from './HeroSection';
import { AboutSection } from './AboutSection';
import { Services } from './Services';
import { Contact } from './Contact';
import { Footer } from './Footer';
import { Counter } from './Counter';


function App() {
  return (
    <div className="App">
      <Navbar />
      <HeroSection />
      <AboutSection />
      <Counter />
      <Services />
      <Contact />
      <Footer />
      <style jsx global>{`
        .home {
        color: #ffa500;
        text-decoration: underline;
        }
      `}
      </style>
    </div>
  );
}

export default App;

