import React from 'react';
import { Link } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import image from '../src/about-img.jpg';
import './App.css'; 
/**
 * AboutSection component renders the "About Us" section of the webpage.
 * It provides an overview of DataFitt's services and expertise in data analytics,
 * predictive modeling, and AI solutions. The section includes a descriptive text
 * and buttons for navigation to learn more about the company or get in touch.
 * Additionally, it displays an image to visually represent the company's mission.
 */
export const AboutSection = () => {
  return (
    <section className="container my-5" style={{backgroundColor:'#f9f9f9'}}>
      <div className="row align-items-center">
        <article className="col-md-6" style={{padding:'30px'}}>
          <h3 className="card-title mb-3" style={{color:'#ffa500', fontWeight:'bold'}}>Turning Data into Your Competitive Edge</h3>
          <p className="card-text">
          At DataFitt, we believe data should be more than just 
          numbers—it should drive real, measurable growth. 
          Imagine having seamless data pipelines, gaining deep 
          insights into user behavior, and utilizing custom dashboards 
          that empower you to make informed decisions faster. 
          Our Advanced Analytics and Optimization Solutions ensure 
          your data is always working for you, not against you. Plus, 
          with our Training & Consultation Services, your team will always stay ahead of the curve.
          </p>
          <div className="button-container">
            <Link to="/AboutCompany">
              <button className="cus-button filled-btn" style={{width:'100%', borderRadius:'10px'}}>Discover More</button>
            </Link>
          </div>
        </article>
        <div className="col-md-6">
          <img
            src={image}
            alt="Team collaborating on data analytics"
            className="img-fluid rounded"
          />
        </div>
      </div>
    </section>
  );
}