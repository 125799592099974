import React from 'react';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Navbar } from './Navbar';
import {Contact}from './Contact';
import {Footer}from './Footer';
import Image from '../src/DataPipe.png';
import { Helmet } from 'react-helmet';


export const  DataPipe=() =>{
  return (
    <div className='app'>
    <Helmet>
      <title>Data Pipeline & ETL Solutions - Data Fitt</title>
    </Helmet>
      <Navbar />
      <div className="Sub-hero-section d-flex align-items-end justify-content-center" style={{position:'relative',}}>
            <video className="background-video" autoPlay loop muted style={{width:'100%'}}>
                <source src={require('../src/bgvid2.mp4')} type="video/mp4" />
            </video>
        <div className="container">
            <h1>Data Pipeline & ETL Solutions</h1>
        </div>
    </div>
    <div className="container my-5">
      <h2 className="text-warning mb-3">Data Pipeline & ETL Solutions</h2>
      <p className="mb-4">
        We Have Successfully Developed And Implemented Robust Data Migration And ETL Solutions That Facilitate
        Efficient Data Transfer And Analysis. Our Projects Includes:
      </p>
      <div className="row">
        <div className="col-md-6">
          <ol className="list-group">
            <li className="list-group-item border-0 ps-0">
              <h5>1.Automated Data Migration From PostgreSQL To Google BigQuery:</h5>
              <p>
                We Engineered An Automated Pipeline That Ensures Seamless
                Real-Time Data Transfer, Enabling Advanced Analytics In A
                Scalable Cloud Environment. This Project Significantly Improved
                Data Accessibility And Performance While Minimizing Costs For
                Our Clients.
              </p>
            </li>
            <li className="list-group-item border-0 ps-0">
              <h5>2.Data Migration From ODK To PostgreSQL:</h5>
              <p>
                We Created A Custom Integration Pipeline That Connects Open
                Data Kit (ODK) With PostgreSQL, Allowing For Efficient Storage
                And Management Of Field-Collected Data. This Solution
                Streamlined Data Processing, Making It Easier For Stakeholders To
                Access And Analyze The Data For Informed Decision-Making.
              </p>
            </li>
          </ol>
        </div>
        <div className="col-md-6">
            <img src={ Image } width="100%" alt="Data Pipeline" className="img-fluid rounded-start"/>
        </div>
      </div>
      <ul className="list-group">
      <li className="list-group-item border-0 ps-0">
              <h5>3.Data Migration From ODK To Google BigQuery:</h5>
              <p>
                We Implemented A Scalable Process To Migrate Large ODK Datasets Into Google BigQuery. By Leveraging BigQuery's High-Speed
                Processing Capabilities, We Ensured Fast Analytics For Extensive Datasets, Enhancing Data Management And Analysis Efficiency For Our
                Users.
              </p>
            </li>
            <li className="list-group-item border-0 ps-0">
              <h5>4.ETL For Dashboard Data Models:</h5>
              <p>
                We Designed And Executed ETL Processes To Support Dynamic, Real-Time Dashboards. This Involved Creating Optimized Data Pipelines
                For Extracting, Transforming, And Loading Data From Multiple Sources Into Data Models, Resulting In Fast Insights And Improved
                Decision-Making Through Effective Data Visualization.
              </p>
            </li>
      </ul>
      <p className="mt-4">
        Through These Projects At DataFitt Company, We Have Demonstrated Our Ability To Build Efficient Data Pipelines, Ensuring Seamless
        Data Flow And Powerful Analytics Capabilities. Our Expertise Lies In Transforming Complex Data Into Actionable Insights, Enhancing
        Data-Driven Decision-Making Across Organizations.
      </p>
    </div>
    <style jsx global>{`
        .servives {
        color: #ffa500;
        text-decoration: underline;
        }
        .DataPipe {
        color: #ffa500;
        text-decoration: underline;
        }
      `}
      </style>
    <Contact />
    <Footer />
    </div>
  );
};
