import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Navbar } from './Navbar';
import {Contact}from './Contact';
import {Footer}from './Footer';
import Image from '../src/dataintergrity.png';
import Image2 from '../src/dataintergrity-bar.png';
import { Helmet } from 'react-helmet';


export const  ProOpt=() =>{
  return (
    <div className='app'>
    <Helmet>
      <title>Data Integrity & Optimization Solutions - Data Fitt</title>
    </Helmet>
      <Navbar />
      <div className="Sub-hero-section d-flex align-items-end justify-content-center" style={{position:'relative',}}>
            <video className="background-video" autoPlay loop muted style={{width:'100%'}}>
                <source src={require('../src/bgvid2.mp4')} type="video/mp4" />
            </video>
        <div className="container">
            <h1>Data Integrity & Optimization Solutions</h1>
        </div>
    </div>
    <div className="container my-5">
      <h2 className="text-warning mb-3">Data Integrity & Optimization Solutions</h2>
      <div className="mb-4 container-fluid d-flex justify-content-center" style={{backgroundColor:'#f8f9fa',padding:'20px',borderRadius:'20px'}}>
      <img src={ Image } width="60%" alt="Data Pipeline" className="img-fluid rounded-start"/>
      </div>
      <p className="mb-4">
      Each phase in this process is essential to ensuring that the dashboard serves its purpose of providing clear, actionable insights to drive business decisions.
      </p>
      <div className="row">
        <div className="col-md-12">
          <ol className="list-group">
            <li className="list-group-item border-0 ps-0">
              <h5>Project 1: Solving Historical Data Discrepancy</h5>
              <p>
              Implemented solutions to identify and correct discrepancies in historical data, ensuring accurate reporting and improving the reliability of data-driven decision-making.
              </p>
            </li>
            <li className="list-group-item border-0 ps-0">
              <h5>Project 2: User's Engagement Time Calculation on Product Features</h5>
              <p>
              Developed methods to calculate user engagement time across various product features, providing actionable insights to enhance user experience and optimize feature usage.
              </p>
            </li>
      <li className="list-group-item border-0 ps-0">
              <h5>Project 3: Google BigQuery Cost Optimization</h5>
              <p>
              Streamlined the use of Google BigQuery by optimizing queries and data storage, reducing operational costs while maintaining performance and efficiency. 
              </p>
            </li>
      <div className="mb-4 container-fluid d-flex justify-content-center" style={{backgroundColor:'#f8f9fa',padding:'20px',borderRadius:'20px'}}>
      <img src={ Image2 } width="100%" alt="Data Pipeline" className="img-fluid rounded-start"/>
      </div>
            <li className="list-group-item border-0 ps-0">
              <h5>Project 4: Data Integrity Audits</h5>
              <p>
              Conducted regular audits to ensure the accuracy and integrity of collected data, identifying and addressing potential issues before they affect reporting or analysis.
              </p>
            </li>
            <li className="list-group-item border-0 ps-0">
              <h5>Project 5: Real-Time Data Quality Monitoring</h5>
              <p>
              Set up systems for real-time monitoring of data flows to identify and fix data quality issues instantly, ensuring high-quality, real-time data for analysis and reporting.
              </p>
            </li>
          </ol>
        </div>
      </div>
      <p className="mt-4">
        Through These Projects At DataFitt Company, We Have Demonstrated Our Ability To Build Efficient Data Pipelines, Ensuring Seamless
        Data Flow And Powerful Analytics Capabilities. Our Expertise Lies In Transforming Complex Data Into Actionable Insights, Enhancing
        Data-Driven Decision-Making Across Organizations.
      </p>
    </div>
    <style jsx global>{`
        .servives {
        color: #ffa500;
        text-decoration: underline;
        }
        .ProOpt {
        color: #ffa500;
        text-decoration: underline;
        }
      `}
      </style>
    <Contact />
    <Footer />
    </div>
  );
};
